import { TaskAggregatorNetworkError } from './TaskAggregatorNetworkError';
import {
	PagedResponse,
	Paging,
	SortDirection,
	SortField,
	TaskCategory,
	TaskDto,
	TaskType,
} from './types';

interface Configuration {
	origin: string;
	apiPath: string;
}

const configuration: Configuration = {
	origin: window.origin,
	apiPath: '/io/taskaggregator/v1/',
};

export function configureHttp(configure: (current: Configuration) => void) {
	configure(configuration);
}

async function createRequest(path: string, init: RequestInit = {}) {
	const url = `${configuration.origin}${configuration.apiPath}${path}`;
	init.headers = { ...init.headers, 'X-BFF-CSRF': 'true' };
	return new Request(url, init);
}

export const TaskAggregatorHttp = {
	async getAggregatedTasks(
		containerRID: string,
		includedTypes?: TaskType[],
		paging?: Paging,
		assignedUserRID?: string,
		kind?: TaskCategory,
		sortField?: SortField,
		sortDirection?: SortDirection
	) {
		const searchParams = new URLSearchParams();
		includedTypes?.forEach(type => {
			searchParams.append('type', type);
		});
		searchParams.append('containerRID', containerRID);
		if (assignedUserRID) {
			searchParams.append('assignedUserRID', assignedUserRID);
		}

		if (kind) {
			searchParams.append('kind', kind);
		}

		if (sortField) {
			searchParams.append('sort', sortField);
		}

		if (sortDirection) {
			searchParams.append('direction', sortDirection);
		}

		if (paging?.take) {
			searchParams.append('Take', paging.take.toString());
		}

		if (paging?.continuationToken) {
			searchParams.append('ContinuationToken', paging.continuationToken);
		}

		const request = await createRequest(`tasks?${searchParams}`, { method: 'GET' });
		const response = await fetchWithError(request, 'Unable to get aggregated tasks');
		const text = await response.text();
		return JSON.parse(text, dateReviver) as PagedResponse<TaskDto>;
	},
};

async function fetchWithError(request: Request, errorMessage: string) {
	const response = await fetch(request);
	if (response.status > 399) {
		const text = await response.text();
		throw text
			? JSON.parse(text)
			: new TaskAggregatorNetworkError(
					`${errorMessage} with status code ${response.status}`,
					response
			  );
	}

	return response;
}

function dateReviver(_key: string, value: string) {
	if (typeof value === 'string') {
		if (
			/^\d{4}-[01]\d-[0-3]\d(T[0-2]\d:[0-5]\d:[0-5]\d(\.\d*)?(Z|[+-]\d{2}:\d{2})?)?$/.test(
				value
			)
		) {
			return new Date(value);
		}
	}

	return value;
}
