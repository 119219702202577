import React from 'react';
import { SpinnerIcon } from '@sharefiledev/antd-config';
import { ExtensionProps } from '../SolutionViewEngine/Blocks';

const LazyBlockDevelopmentPage = React.lazy(() => import('./BlockDevelopmentPage'));
export const AsyncBlockDevelopmentPage: React.FC<ExtensionProps> = props => (
	<React.Suspense fallback={<SpinnerIcon />}>
		<LazyBlockDevelopmentPage {...props} />
	</React.Suspense>
);
