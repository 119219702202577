import * as React from 'react';
import { Api } from '@citrite/sf-api';
import { TaskList } from '@sharefiledev/icons';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { AsyncBlockDevelopmentPage } from './Pages';
import {
	AsyncClientTaskListBlock,
	AsyncTaskListBlock,
	taskAggregatorBlockTypes,
} from './SolutionViewEngine/Blocks';
import { configureHttp } from './TaskAggregatorHttp';

export function setup(app: PiletApi) {
	// @ts-ignore TODO: remove after type diff is fixed
	app.sf.registerSfApiConfigHandler(Api.configure);

	app.registerExtension(taskAggregatorBlockTypes.clientTaskListBlock, ({ params }) => (
		<AsyncClientTaskListBlock piletApi={app} {...params} />
	));

	app.registerExtension(taskAggregatorBlockTypes.taskListBlock, ({ params }) => (
		<AsyncTaskListBlock piletApi={app} {...params} />
	));

	const smokeTestCustomers = [
		'pilet-tests.sharefiletest.com',
		'pilet-tests.sharefilestaging.com',
		'pilet-tests.sharefile.com',
	];

	if (
		process.env.NODE_ENV === 'development' ||
		smokeTestCustomers.includes(location.hostname)
	) {
		app.registerPage('/task-aggregator-block-development', () => (
			<AsyncBlockDevelopmentPage piletApi={app} />
		));

		app.sf.registerLeftNavComponent({
			href: '/task-aggregator-block-development',
			title: () => 'Task Aggregator Block Dev',
			icon: TaskList,
			weight: 50,
		});

		if (typeof app.meta.config.apiOrigin === 'string') {
			configureHttp(config => (config.origin = app.meta.config.apiOrigin));
		}
	}
}
