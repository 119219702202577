import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { BlockParams } from '@sharefiledev/solution-view-engine';
import { Skeleton, Spin } from 'antd';
import { TaskCategory } from '../../types';

export const AntDPrefix = 'sf-task-aggregator-pilet';

export const taskAggregatorBlockTypes = {
	clientTaskListBlock: 'urn:block:task-aggregator:client-task-list' as const,
	taskListBlock: 'urn:block:task-aggregator:task-list' as const,
};

export interface VerticalizationParams {
	verticalContextKey?: string;
}

export interface TaskListBlockParams extends VerticalizationParams {
	containerRID: string;
	kind?: TaskCategory;
}

export type ExtensionProps = {
	piletApi: PiletApi;
};

const LazyClientTaskListBlock = React.lazy(() => import('./ClientTaskListBlock'));
export const AsyncClientTaskListBlock: React.FC<
	ExtensionProps & TaskListBlockParams & BlockParams
> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyClientTaskListBlock {...props} />
	</React.Suspense>
);

const LazyTaskListBlock = React.lazy(() => import('./TaskListBlock'));
export const AsyncTaskListBlock: React.FC<
	ExtensionProps & TaskListBlockParams & BlockParams
> = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyTaskListBlock {...props} />
	</React.Suspense>
);
